<template>
  <section class="fdb-block" style="background-image: url(imgs/hero/blue.svg);">
    <div class="col-md-5 mx-auto">
      <div class="card rounded shadow-lg">
        <div class="card-body">
          <div class="text-center">
            <img src="/images/siged2.png" alt="homepage" class="dark-logo" />
          </div>
          <div class="card-title text-center">
            <h2>{{ title }}</h2>
          </div>
          <br>
          <form v-on:submit.prevent="saveItem()">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Correo electrónico (*):</label>
                  <input type="email" class="form-control col-md-8" v-model="item.email" required disabled  >
                  </div>
              </div>
            </div>
          <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Contraseña Nueva (*):</label>
                  <input type="password" class="form-control col-md-8" v-model="item.contrasena" required >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Confirmar (*):</label>
                  <input type="password" class="form-control col-md-8" v-model="item.confirmar" required>
                </div>
              </div>
            </div>
            <div v-if="message">
              <v-progress-circular
                v-if="showProgress"
                :size="50"
                :width="5"
                color="blue"
                indeterminate
              ></v-progress-circular>
              <p class="alert alert-warning" role="alert">{{ message }}</p>
            </div>
            <div class="row text-center">
              <div class="col-6">
                <button type="submit" class="btn btn-primary float-center">{{ buttontext }}</button>
              </div>
              <div class="col-6">
                <router-link :to="{ name: 'Signin' }" class="btn btn-secondary float-centert">Cancelar</router-link>
              </div>            
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  //import { VProgressCircular } from 'vuetify/lib';

  //import toastr from 'toastr';

  export default {
    components: {
      //VProgressCircular
    },
    data(){
      return{
        add: true, //Create a new document
        close: true, // Close the page and return to list
        title: '',
        item: {},       
        message: '',
        buttontext: '',
        usuario: {},
        showProgress: false        
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      },
      cancelButtonVisibility() {
        if (this.addInd) {
          return 'visibility:hidden'
        } else {
          return 'visibility:visible'
        }
      }
    },
    created: function() {        
          this.title = 'Recuperar contraseña';
          this.buttontext = 'Recuperar';    
          this.showProgress = true;
          let uri = '/usuarios/securityreset';
          this.axios.post(uri, { token: this.$route.params.token })
          .then((response) => {
            this.showProgress = false;
            this.item = response.data;           
          }) 
          .catch(err => {
            console.log(err);
            this.$router.replace({ name: 'PwdNotFound' });
          });
        // =================      
    },
    mounted(){
       
    },
//METODOS DE   VUE --------METODOS DE   VUE------METODOS DE   VUE
    methods: {
      setClose(close){
        this.close = close;
      },
      saveItem(){
        var usuario={};
        usuario.email=this.item.email;        
        usuario.contrasena=this.item.contrasena;     
        if (this.item.contrasena != this.item.confirmar) {
             this.message = '¡La contraseña no ha sido confirmada';
             return;
          }    

        if (this.item.contrasena.length < 8){
          this.message = 'Atención. La contraseña debe tener una longitud mínima de 8 caracteres';
          return;
        }

        let uri = "/usuarios/securityresetpwd";
        this.axios.post(uri, { token: this.$route.params.token, usu_pwd_new: usuario.contrasena })
        .then((response) => {
            this.$router.replace({ name: 'PwdSuccess' });
        }).catch((err) => {
            this.showProgress = false;
            this.message = '¡No se ha podido cambiar la contraseña. Verifique los datos y vuelva a intentarlo';
        });          
      }

    }//FIN DE TODOS LOS METODOS
  }
  </script>

  <style>
    .warning {
      color: #ff0000;
    }
  </style>